.row {
    background: rgba( 255, 255, 255, 0.10 );
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    backdrop-filter: blur( 50.0px );
    -webkit-backdrop-filter: blur( 50.0px );
    border-radius: 5px;
    padding: 10px;
}

.table {
    border-collapse: 'separate';
    border-spacing: '0px 10px' !important;
}

.wrapper {
    height: 100%;
}
