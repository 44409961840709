.paper {
    margin-top: 20px;
}

.btmline{
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px rgb(82, 82, 82) solid;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
}

.lawyer__infofield {
    margin-top: 11px;
    margin-bottom: 11px;
    font-size: 14px;
    line-height: 20px;
}
.lawyer__infofield__position {
    margin-bottom: 11px;
    font-size: 14px;
    line-height: 20px;
}
.small__red{
    color: #F03131;
    font-weight: 700;
}

.lawyer__infofield__header{
    color: #BBBBBB;
}

.transparentButton{
    background: transparent;
    border: none !important;
    color: white;
    text-align: left;
}

.transparentButton:hover {
    color: #ff1100;
    transition: 0.3s;
}