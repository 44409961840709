.transparentButton{
    background: transparent;
    border: none !important;
    color: white;
    text-align: left;
}

.transparentButton:hover {
    color: #ff1100;
    transition: 0.3s;
}

.transparentButton__current{
    background: transparent;
    border: none !important;
    color: white;
    text-align: left;
}

.transparentButton__current:hover {
    color: #000000;
    transition: 0.3s;
}


.semrush__link {
    text-decoration: none;
    color: white;
}

.semrush__link:hover {
    color: #ff1100;
    transition: 0.3s;
}

.semrush__link__current {
    text-decoration: none;
    color: white;
}

.semrush__link__current:hover {
    color: #000000;
    transition: 0.3s;
}

