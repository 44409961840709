.btn {
    height: 40px;
    width: 100%;
    background: linear-gradient(90deg, #F03131 0%, #F05F31 100%);
    color: white !important;
    border-radius: 5px;
    font-weight: 1000;
    font-size: 17px;
}

.model__btn {
    height: 40px;
    width: 100%;
    border-radius: 5px;
    font-weight: 1000;
    font-size: 17px;
}

.inp {
    padding-left: 10px;
    background: #464646;
    color: white;
    border-radius: 5px;
    font-size: 15px;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 0;
}

.btmline{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    padding-bottom: 10px;
    border-bottom: 1px rgb(82, 82, 82) solid;
    margin-bottom: 20px;
}