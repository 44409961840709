
.leaders{
  margin: auto;
  text-align: center;
}

.paper {
  margin:auto;
  width: 98%;
  padding: 10px;
  color: white;
}

.background {
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
  height: 100vh;
}


.glass__container {
  background: rgba( 255, 255, 255, 0.10 );
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur( 50.0px );
  -webkit-backdrop-filter: blur( 50.0px );
  border-radius: 5px;
  height: 97.5vh;
  padding: 20px;
  padding-bottom: 50px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: relative;
}

.glass__container__leaders {
  background: rgba( 255, 255, 255, 0.10 );
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur( 50.0px );
  -webkit-backdrop-filter: blur( 50.0px );
  border-radius: 5px;
  height: 97.5vh;
  padding: 20px;
  padding-top: 83px;
  padding-bottom: 50px;
  
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: relative;
}






.logo__red{
  color: #F03131 !important;
  font-size: 34px;
  font-weight: 400;
}


.logo__white {
  color: white;
  font-size: 34px;
  font-weight: 700;
}

.logo__small {
  color: white;
  font-size: 12px;
  font-weight: 700;
}

@media screen and (max-width: 1200px){
  body::-webkit-scrollbar {
    display: none;
  }
  body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .logo__red{
    color: #F03131;
    font-size: 20px;
    font-weight: 400;
  }
  .logo__white {
    color: white !important;
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  
  .logo__small {
    color: white;
    font-size: 12px;
    font-weight: 700;
  }
}


.logo {
  margin-bottom: 20px;
}

.search__for {
  top: 17px;
  left: 20px;
  width: 97%;
  padding-bottom: 20px;
  border-bottom: 1px rgb(82, 82, 82) solid;
  position: absolute;
}



.small__red{
  color: #F03131;
  font-size: 12px;
  font-weight: 400;
}

.powered__by{
  font-size: 12px;
  font-weight: 400;
  bottom: 17px;
  right: 20px;
  position: absolute;
}

.pagination{
  color: white !important;
  font-size: 12px;
  font-weight: 400;
  bottom: 0;
  right: 20px;
  position: absolute;
}

.scrolldiv{
  height: 100%;
}


@media screen and (max-width: 960px){
  html {
    overflow-x: hidden;
  }
  body {
    background-color: rgb(22, 22, 22);
    overflow-x: hidden;
    position: relative;
    scroll-behavior: smooth;

  }
  .background {
    background: none;
  }
  .paper{
    padding: 0px;
  }
  .glass__container{
    height: auto;
    margin: 10px;
    margin-bottom: 0px;
    background: #2e2e2e;
    box-shadow: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }
  .glass__container__leaders{
    padding-top: 20px;
    height: auto;
    margin: 10px;
    margin-bottom: 0px;
    background: #2e2e2e;
    box-shadow: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }
  .search__for {
    border: 0;
    position: static;
  }
  
}

